import { useEffect, useState } from "react";
import UserEntry from "./components/UserEntry";

function App() {
  const [data, setData] = useState([]);

  const getData = async () => {
    const response = await fetch('https://cosmic.lebogo.tk/ranklist/588684286782341122');
    const data = await response.json();
    setData(data);
  }


  useEffect(() => {
    const intervalHandle = setInterval(getData, 1000 * 60 * 5);

    setTimeout(() => {
      if (document.location.hash) {
        const element = document.querySelector(decodeURI(document.location.hash));
        if (element) element.scrollIntoView();
      }
    }, 1000);

    getData();
    return () => {
      clearInterval(intervalHandle);
    }
  }, [])

  const entries = data.map(entry => {
    return (<UserEntry data={entry} key={entry.id} />)
  });

  return (
    <div className="App">
      <h3 className="display-3 mt-2 text-center">TheCosmicVoid - Leaderboard</h3>
      <div className="container">
        {entries}
      </div>
    </div>
  );
}

export default App;
