import { useEffect, useState } from 'react';
import '../styles/entry.css';

export default function UserEntry({ data }) {

    const [size, setSize] = useState((window.innerHeight * 0.20 > window.innerWidth * 0.20) ? window.innerWidth * 0.20 : window.innerHeight * 0.20)

    const resizeImages = () => {
        let value = (window.innerHeight * 0.20 > window.innerWidth * 0.20) ? window.innerWidth * 0.20 : window.innerHeight * 0.20;
        if (value !== size) setSize(value);
    }

    useEffect(() => {
        const intervalHandle = setInterval(resizeImages, 100);
        return () => {
            clearInterval(intervalHandle);
        }
    }, [])

    return (
        <div className={size >= 500 ? 'entry m-4 p-2' : 'entry me-3 p-1'} id={data.username} onClick={() => {
            document.location.hash = data.username;
        }}>
            <table className="table-dark">
                <thead><tr>
                    <td></td>
                    <td></td>
                </tr></thead>
                <tbody>
                    <tr>
                        <td rowSpan="3">
                            <img
                                className="m-3"
                                src={data.userURL}
                                alt={data.username + " Profile pic"}
                                style={{
                                    width: size + "px",
                                    height: 'auto',
                                    borderRadius: '50%',
                                }} />
                        </td>
                        <td style={{ width: '100%' }}>
                            <h3 className={size >= 500 ? 'display-3' : 'display-6'} style={{ display: 'inline-block' }}>#{data.rank} {data.username}</h3>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center">
                            <span>Level: {data.level} | {data.relativePoints >= 0 ? data.relativePoints : 0}/{data.relativeNextLevelPoints} XP</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={
                                    { width: data.percentage * 100 + "%" }
                                } aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div></td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}